/** @format */

import React from "react";
import { FaArrowDown } from "react-icons/fa";

const Vision = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <h1 className='sm:w-6/12 w-full'>
              Easy Auto Trans Strives To Deliver A 5-Star Vehicle-Shipping Experience
              To Every Customer.
            </h1>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
            <div className='col-md-6 py-5'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
                
              </div>
              <h2 className='mt-5'>OUR VISION</h2>
              <p className='mt-5'>
                We aim to establish ourselves as the benchmark for vehicle
                shipping, catering to a diverse range of customers including
                individuals, corporations of all sizes, and business partners
                across the United States.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 py-5'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
             
              </div>
              <h2 className='mt-5'>OUR MISSION</h2>
              <p className='mt-5'>
                Easy Auto Trans’s mission is to provide every customer with a 5-star
                vehicle-shipping experience by combining innovative logistics
                solutions with a strong dedication to delivering excellent
                service that exceeds expectations.
              </p>
            </div>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
            <div className='col-md-6 py-5'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
            
              </div>
              <h2 className='mt-5'>OUR VALUES</h2>
              <p className='mt-5'>
                Easy Auto Trans’s culture and business strategy are built upon a set of
                core values that include integrity, service excellence,
                leadership, innovation, and simplicity in operations. These
                deeply ingrained principles serve as the foundation for our
                company’s ethos.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <div>
              <h1>Ready To Get A Car Shipping Quote With Easy Auto Trans?</h1>
              <p>
                Use the calculator below or call us at --- ---- ---- today!
              </p>
              <div>
                <button className='flex items-center'>
                  <FaArrowDown className='mx-1' /> Get a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vision;
