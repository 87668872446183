/** @format */

import React, { useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Link } from "react-scroll";
import GetAQuote from "../Utils/GetAQuote";

const Hero = () => {
  return (
    <>
      <div className='hero_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <h2>
                Quick And Reputable Vehicle Shipping Business Operating
                Nationally
              </h2>
              <p>
                With several worldwide locations and a wide network of partners
                and <br /> agents, Easy Auto Trans Auto Trans is a top provider of
                automobile <br />
                transportation services.
              </p>
              <div className='btn_main'>
                <Link to='explore'>
                  {" "}
                  <button>
                    Explore More <AiOutlineArrowDown size={20} />
                  </button>
                </Link>
              </div>
            </div>
            <div className='col-md-4 mt-3'>
              <div className='card'>
                <GetAQuote />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
