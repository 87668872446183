/** @format */

import React from "react";

const About = () => {
  return (
    <>
      <div className='about_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 m-auto '>
              <div className='d-flex justify-content-end  align-items-center gap-2'>
                <div className='border_top'></div>
                <h5>Easy Auto Trans AUTO TRANS</h5>
              </div>
              <h4>ABOUT US AND OUR SKILLS</h4>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-5 m-auto'>
              <p>
                We have devoted more than 20 years to offering our customers the
                most dependable and open vehicle shipping services and costs.
                With over 20 years of expertise in car shipping, hundreds of
                thousands of automobiles transported, and many positive client
                testimonials, our track record speaks for itself.
              </p>
            </div>
            <div className='col-md-3'>
              <img src='assets/images/a1.svg' className='img-fluid' alt='' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
