/** @format */

import React from "react";
import { Link } from "react-router-dom";

const CrossCountry = () => {
  return (
    <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <p className='text-base font-semibold text-indigo-600'>Cross Country</p>
        <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
          Coming Soon
        </h1>
        <p className='mt-6 text-base leading-7 text-gray-600'>
          {/* Sorry, we couldn’t find the page you’re looking for. */}
        </p>
        <div className='mt-10 flex items-center justify-center gap-x-6'>
          <Link
            to='/'
            className='rounded-sm bg-slate-900 px-4 py-3.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600'>
            <span aria-hidden='true'>&larr;</span> Go back home
          </Link>
          <Link to='/help' className='text-sm font-semibold text-gray-900'>
            Contact support <span aria-hidden='true'>&rarr;</span>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default CrossCountry;
