/** @format */

import React from "react";

const OutSource = () => {
  return (
    <>
      <div
        className='outsource_sec overflow-hidden '
        id='explore'
        name='explore'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
              <ul className='outsource_left d-flex flex-column justify-content-around align-items-center'>
                <li>
                  <div className='d-flex  align-items-center justify-end'>
                    <h5>
                      Step 1: <span>Get Quote</span>
                    </h5>
                    <div className='icon_bg'>
                      <img
                        src='assets/images/c1.png'
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className='d-flex  align-items-center justify-end'>
                    <h5>
                      Step 2: <span>Place Order</span>{" "}
                    </h5>

                    <div className='icon_bg'>
                      <img
                        src='assets/images/c2.png'
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className='d-flex  align-items-center justify-end'>
                    <h5>
                      Step 3: <span>Schedule Pickup</span>
                    </h5>

                    <div className='icon_bg'>
                      <img
                        src='assets/images/c3.png'
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className='col-md-6 d-flex justify-content-center align-items-center relative '>
              <div className='spin_img position-relative'>
                <img
                  src='assets/images/image (7)da.png'
                  className='img-fluid ani'
                  alt=''
                />
                <div className='text_bg '>
                  
                  <h3 className='mt-0'>
                    How Do I Book With An Auto Transport Company?
                  </h3>
                  <h6>
                    We Can Handle Any And Every Vehicle That Can Roll, Brake,
                    And Steer.
                  </h6>
                </div>
              </div>
            </div>
            <div className='col-md-3 '>
              <ul className='outsource_left d-flex flex-column justify-content-around align-items-center mt-28 md:mt-0'>
                <li>
                  <div className='flex items-center justify-start'>
                    <div
                      className='icon_bg'
                      style={{ marginRight: "15px", marginLeft: "0px" }}>
                      <img
                        src='assets/images/c4.png'
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <h5>
                      Step 4: <span>Move Vehicle</span>
                    </h5>
                  </div>
                </li>
                <li>
                  <div className='flex items-center justify-start'>
                    <div
                      className='icon_bg'
                      style={{ marginRight: "15px", marginLeft: "0px" }}>
                      <img
                        src='assets/images/c5.png'
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <h5>
                      Step 5: <span>Receive your vehicle</span>
                    </h5>
                  </div>
                </li>
                <li>
                  <div className='flex items-center justify-start'>
                    <div
                      className='icon_bg'
                      style={{ marginRight: "15px", marginLeft: "0px" }}>
                      <img
                        src='assets/images/review.png'
                        className='img-fluid'
                        alt=''
                      />
                    </div>
                    <h5>
                      Step 6: <span>Give us your Feedback</span>
                    </h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OutSource;
