/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Base from "./components/Layouts/Base";
import Offering from "./pages/Offering";
import HowItWork from "./pages/HowItWork";
import Faqs from "./components/Utils/Why/Faqs";
import Reviews from "./components/Utils/Why/Reviews";
import Press from "./components/Utils/Why/Press";
import Vision from "./components/Utils/Why/Vision";
import Help from "./pages/Help";
import CarShipping from "./pages/CarShipping";
import PageNotFound from "./pages/PageNotFound";
import Alpine from "alpinejs";
import { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import CrossCountry from "./pages/CrossCountry";
import OrderForm from "./pages/OrderForm";

function App() {
  useEffect(() => {
    scroll.scrollToTop();
  });
  return (
    <div className='App'>
      <BrowserRouter>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
            <Route path='/our-services' element={<Offering />} />
            <Route path='/how-it-works' element={<HowItWork />} />
            <Route path='/faqs' element={<Faqs />} />
            <Route path='/reviews' element={<Reviews />} />
            <Route path='/press' element={<Press />} />
            <Route path='/vision-mission' element={<Vision />} />
            <Route path='/help' element={<Help />} />
            <Route path='/car-shipping-costs' element={<CarShipping />} />
            <Route path='/cross-country' element={<CrossCountry />} />
            <Route path='/get-a-quote' element={<OrderForm />} />
            <Route path='/*' element={<PageNotFound />} />
          </Routes>
        </Base>
      </BrowserRouter>
    </div>
  );
}

export default App;
