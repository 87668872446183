/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer
        className='footer  text-white'
        style={{ backgroundColor: "#1A2741" }}>
        <div className='container'>
          <div className='row list'>
            <div className='col-md-3 col-12 icons px-4'>
              <img src='assets/images/logo.png' className='img-fluid ' alt='' />
              <p className='my-4'>
                Everyday is a new day for us and we work really hard to satisfy
                our customer everywhere.
              </p>
              {/* <h4>--- ---- ----</h4> */}
            </div>
            <div className='col-md-3 col-6'>
              <ul className='list-unstyled'>
                <h5>Contact Info</h5>
                <li>
                  <Link to='/'>Easy Auto Trans</Link>
                </li>
                <li>
                  <Link>
                    9003 Sunny Brook Ln Pearland,
                    <br /> TX 77584
                  </Link>
                </li>
              </ul>
              <div>
                <p>Email: info@easyautotrans.com</p>
              </div>
            </div>
            <div className='col-md-3 col-6'>
              <ul className='list-unstyled'>
                <h5>Useful Links</h5>
                <li>
                  <Link to='/how-it-works'>How it Works</Link>
                </li>
                <li>
                  <Link to='faqs'>FAQs</Link>
                </li>
                <li>
                  <Link to='our-services'>About Us</Link>
                </li>
                <li>
                  <Link to='help'>Help</Link>
                </li>
                <li>
                  <Link to='/'>Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div className='col-md-3 col-12'>
              <ul className='list-unstyled'>
                <h5>Newsletter</h5>
                <p className='my-4'>
                  Subscribe to our newsletter to get daily discounts & offers.
                </p>
                <div className='mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Email Address'
                  />
                </div>
                <div className='btn_main'>
                  <button>Subscribe</button>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className=' py-4  bg-black'>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <div className='' style={{ color: "#b7b8bb" }}>
              <strong>Copyright © 2024 by Easy Auto Trans Auto Trans.</strong>
            </div>
            <div>
              <marquee behavior='' direction='' className='footer_marquee'>
                ADDRESS: 9003 Sunny Brook Ln Pearland, TX 77584 ICC-MC #:
                1426875 | USDOT# 3885542 | BOND# 10137026
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
