import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

const Banner = () => {
  return (
    <>
        <div className="banner_sec">
            <div className="container">
                {/* <div className='d-flex  align-items-center gap-5 '> */}
                <Carousel
                  responsive={responsive}
                  arrows={false}
                  autoPlay
                  autoPlaySpeed={3000}
                  draggable
                  showArrows={true}
                  focusOnSelect={false}
                  infinite={true}
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}>
                    <div>
                        <img src="assets/images/d1.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d2.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d3.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d4.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d5.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d6.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d7.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d8.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d9.png" className='img-fluid' alt="" />
                    </div>
                    <div>
                        <img src="assets/images/d10.png" className='img-fluid' alt="" />
                    </div>
                    </Carousel>
                {/* </div> */}
            </div>
        </div>
    </>
  )
}

export default Banner