/** @format */

import React from "react";
import { FaArrowDown } from "react-icons/fa6";
const Offering = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth", });
    };

    scrollToTop();
  }, []);
  return (
    <>
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <h1>What We Offering?</h1>
          </div>
        </div>
      </div>
      <div className='container offers py-5'>
        <div className='row'>
          <h2>Industries We Serve</h2>
        </div>
        <div className='serve-card mt-5 m-auto relative overflow-hidden'>
          <span className='block w-[143px] h-[141px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />

          <div className='row'>
            <div className='col-sm-4'>
              <img
                src='assets/images/Dealerships.jpg'
                alt=''
                className=' img-fluid rounded-3 w-100'
              />
            </div>
            <div className='col-sm-8 m-auto'>
              <h4>Auto Dealerships & Groups</h4>
              <p>
                What struggles are you facing in achieving operational
                excellence for your dealership? We can assist you in overcoming
                obstacles like overworked internal resources, a lack of
                visibility and tracking within the supply chain, and
                uncooperative carrier partners.
              </p>
            </div>
          </div>
        </div>
        <div className=' relative overflow-hidden serve-card mt-5 m-auto'>
          <span className='block w-[143px] h-[141px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />

          <div className='row'>
            <div className='col-sm-4'>
              <img
                src='assets/images/Coparts.jpg'
                alt=''
                className=' img-fluid rounded-3 w-100'
              />
            </div>
            <div className='col-sm-8 m-auto'>
              <h4>Auto Auctions & Coparts</h4>
              <p>
                You are operating an auction business with such accuracy that
                even renowned surgeons would be stunned. If a single slip-up,
                misunderstanding, or delay were to occur, it could result in
                significant financial losses for both buyers and sellers as well
                as your business.
              </p>
            </div>
          </div>
        </div>
        <div className=' relative overflow-hidden serve-card mt-5 m-auto'>
          <span className='block w-[143px] h-[141px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />

          <div className='row'>
            <div className='col-sm-4'>
              <img
                src='assets/images/Rental.jpg'
                alt=''
                className=' img-fluid rounded-3 w-100'
              />
            </div>
            <div className='col-sm-8 m-auto'>
              <h4>Rental Car Companies</h4>
              <p>
                The rental car industry has been drastically altered in recent
                years, leading to companies disposing of their stock, shifting
                major parts of their fleets or creating new methods for
                replenishing supplies.
              </p>
            </div>
          </div>
        </div>

        <div className='row mt-5'>
          <h2>For Individuals</h2>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className=' relative overflow-hidden serve-card mt-5 m-auto'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
              <div className='row'>
                <div className='col-sm-4'>
                  <img
                    src='assets/images/Door-To-Door.jpg'
                    alt=''
                    className=' img-fluid rounded-3 w-100'
                  />
                </div>
                <div className='col-sm-8 m-auto'>
                  <h4>Door-To-Door</h4>
                  <p>
                    Door-to-door car shipping is the most practical way to
                    transport a vehicle, as you can choose exactly where it will
                    be picked up and dropped off. The driver of the truck
                    transporting your car will do their best to get as close as
                    possible to this address, abiding by any safety and legal
                    regulations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className=' relative overflow-hidden serve-card mt-5 m-auto'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
              <div className='row'>
                <div className='col-sm-4'>
                  <img
                    src='assets/images/Open-Auto-Transport.jpg'
                    alt=''
                    className=' img-fluid rounded-3 w-100'
                  />
                </div>
                <div className='col-sm-8 m-auto'>
                  <h4>Open Auto Transport</h4>
                  <p>
                    Open auto shipping is the most commonly used form of
                    automobile transportation. Compared to enclosed car
                    shipping, open transport is more straightforward to arrange
                    and also less expensive due to the fact that open trailers
                    can transport up to 8-10 vehicles simultaneously.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <div className=' relative overflow-hidden serve-card mt-5 m-auto'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
              <div className='row'>
                <div className='col-sm-4'>
                  <img
                    src='assets/images/Enclosed-Car-Transport.jpg'
                    alt=''
                    className=' img-fluid rounded-3 w-100'
                  />
                </div>
                <div className='col-sm-8 m-auto'>
                  <h4>Enclosed Car Transport</h4>
                  <p>
                    A type of auto transport that provides extra security for
                    vehicles is enclosed car transport. If you are shipping a
                    classic or luxury vehicle, this could be the best option
                    available. These carriers usually can only accommodate a
                    limited number of cars at once, usually up to 7.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className=' relative overflow-hidden serve-card mt-5 m-auto'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
              <div className='row'>
                <div className='col-sm-4'>
                  <img
                    src='assets/images/Expedited-Car-Shipping.jpg'
                    alt=''
                    className=' img-fluid rounded-3 w-100'
                  />
                </div>
                <div className='col-sm-8 m-auto'>
                  <h4>Expedited Car Shipping</h4>
                  <p>
                    You don't need to provide the driver with a title or
                    registration for the vehicle as long as it isn't being
                    delivered somewhere that requires those documents. Have the
                    car and keys ready, and you'll be all set.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <div className=' relative overflow-hidden serve-card mt-5 m-auto'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
              <div className='row'>
                <div className='col-sm-4'>
                  <img
                    src='assets/images/Hawaii.jpg'
                    alt=''
                    className=' img-fluid rounded-3 w-100'
                  />
                </div>
                <div className='col-sm-8 m-auto'>
                  <h4>Hawaii</h4>
                  <p>
                    The process of sending a car to Hawaii (Kauai, O’ahu, Maui,
                    or the Big Island) is facilitated by either port-to-port or
                    door-to-port transportation services. Shipping from New York
                    City to Honolulu generally takes between 14 and 19 days;
                    from Chicago it takes 13-18 days; and from Los Angeles it is
                    8-12 days.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className='mid-banner mt-5 relative'>
          <div class='background-overlay'></div>
          <div className='container'>
            <div className='inner flex justify-around items-center'>
              <div>
                <h1>Ready To Transport Your Car With Easy Auto Trans?</h1>
                <p>
                  Our car shipping advisors are available by calling (832)
                  521-7324 to answer all your questions.
                </p>
              </div>
              <div>
                <button className=' flex items-center'>
                  <FaArrowDown className='mx-1' /> Get a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
      <div className='container-fluid bg-slate-200 py-5 serve'>
        <h2 className='text-center text-[36px]'>What We Serve?</h2>
        <div className='row mt-4'>
          <div className='col-md-3'>
            <div className='serve-card'>
              <img
                src='/assets/images/car-1.png'
                className='img-fluid w-32 m-auto py-4'
                alt=''
              />
              <h4>Snowbirds Auto Transport</h4>
              <p>
                Easy Auto Trans Auto Trans provides a safe and secure service to
                transport your car directly to your destination. You may also
                include one suitcase or box of personal items (weighing less
                than 100 lbs.) in the trunk at no additional cost. However,
                please note that due to safety regulations, the assigned trucker
                has the right to check the trunk for any prohibited items.
              </p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='serve-card'>
              <img
                src='/assets/images/review.png'
                className='img-fluid w-32 m-auto py-4'
                alt=''
              />
              <h4>Classic Car Lovers</h4>
              <p>
                That’s why Easy Auto Trans has the largest American coverage and
                longest-running record of quality car transportation services in
                the country. Whether you need to move one classic vehicle or an
                entire fleet, you can count on us for safe and reliable
                transportation.
              </p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='serve-card'>
              <img
                src='/assets/images/service.png'
                className='img-fluid w-32 m-auto py-4'
                alt=''
              />
              <h4>Car Resellers</h4>
              <p>
                Montway's speed and convenience make car resellers and buyers'
                lives easier by scheduling pick-ups and deliveries. With
                Easy Auto Trans, you can rest assured that your car will be delivered
                safely door-to-door across the country with open or enclosed
                transport.
              </p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='serve-card'>
              <img
                src='/assets/images/car-1.png'
                className='img-fluid w-32 m-auto py-4'
                alt=''
              />
              <h4>Nationwide Military Car Shipping</h4>
              <p>
                Members of the military who are assigned to a new duty station
                are entitled to have one privately owned vehicle (POV) shipped
                at government expense. Requests for alternate port approval must
                be submitted to the Military Traffic Management Command (MTMC).
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offering;
