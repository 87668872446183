/** @format */

import React from "react";
import GetAQuote from "../components/Utils/GetAQuote";

const OrderForm = () => {
  return (
    <div className='hero_sec'>
      <div className='container'>
        <div className=' max-w-2xl m-auto'>
          <div className='card'>
            <GetAQuote />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderForm;
