import React from "react";
import {AiOutlineArrowDown} from 'react-icons/ai'
import { Link } from "react-router-dom";

const Transport = () => {
  return (
    <>
      <div className="transport_sec">
        <div className="container">
          <div className="row">
          <div className="col-md-6">
              <div className="imgs"></div>
                {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
            <div className="col-md-6">
              <h2>
                How Much Do Car Transport <span>Companies Charge</span>?
              </h2>
              <p>
                The cost of using an auto transport company is determined by
                prevailing market conditions for nationwide car shipping.
              </p>
              <p>
                The amount you pay for car shipping will vary based on what car
                you are transporting, its destination, size, any modifications
                it has, total mileage of the journey, transport type (open or
                enclosed trailer), and when you book.
              </p>
              <strong>
                Don’t forget to check for additional promotions and savings. Get
                a quote with our car shipping calculator, use our Live Chat, or
                call --- ---- ----.
              </strong>
              <div className="btn_main">
              <Link to='/how-it-works'> <button className="flex items-center">
                More about your price from car moving companies <AiOutlineArrowDown size={20} className="mx-1" />
                </button></Link> 
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Transport;
