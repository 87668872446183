/** @format */

import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaEnvelopeOpen, FaLocationArrow } from "react-icons/fa";

const Help = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      {/* <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <div>
              <h1>Help Center</h1>
              <p>
                Find Your Car Shipping Cost In Seconds With Easy Auto Trans‘s Auto
                Trans Calculator.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/*  */}
      <div
        className='section-bg relative'
        style={{
          backgroundImage: "url(https://i.ibb.co/9p3Cnk9/slider-2.jpg)",
        }}
        data-scroll-index={7}>
        <div className='overlay pt-100 pb-100 '>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 d-flex align-items-center'>
                <div className='contact-info'>
                  <h2 className='contact-title'>Have Any Questions?</h2>
                  <p>
                    Find Your Car Shipping Cost In Seconds With Easy Auto Trans‘s Auto
                    Trans Calculator.
                  </p>
                  <ul className='contact-info'>
                    <li>
                      <div className='info-left'>
                        <BsFillTelephoneFill className='text-white' />
                      </div>
                      <div className='info-right'>
                        <h4>+xxxxxxxxxxxx</h4>
                      </div>
                    </li>
                    <li>
                      <div className='info-left'>
                        <FaEnvelopeOpen className='text-white' />
                      </div>
                      <div className='info-right'>
                        <h4>info@example.com</h4>
                      </div>
                    </li>
                    <li>
                      <div className='info-left'>
                        <FaLocationArrow className='text-white' />
                      </div>
                      <div className='info-right'>
                        <h4>--- ---- ----</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-6 d-flex align-items-center'>
                <div className='contact-form relative '>
                  {/*Contact Form*/}
                  <form id='contact-form' method='POST'>
                    <input
                      type='hidden'
                      name='form-name'
                      defaultValue='contactForm'
                    />
                    <div className='row relative'>
                      <div className='col-md-12'>
                        <div className='form-group relative'>
                          <input
                            type='text'
                            name='name'
                            className='form-control'
                            id='first-name'
                            placeholder='Enter Your Name *'
                            required='required'
                          />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <input
                            type='email'
                            name='email'
                            className='form-control'
                            id='email'
                            placeholder='Enter Your Email *'
                            required='required'
                          />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <textarea
                            rows={4}
                            name='message'
                            className='form-control'
                            id='description'
                            placeholder='Enter Your Message *'
                            required='required'
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        {/*contact button*/}
                        <button className='btn-big btn btn-bg'>
                          Send Us <i className='fas fa-arrow-right' />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='container help py-5'>
        <h2>Here For You, Every Step Along The Way</h2>
        <div className='row py-4'>
          <div className='col-lg-4 col-md-6 col-sm-12 relative'>
            <div className='serve-card relative'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[90%] top-[-50px] rounded-[30%]' />

              <img
                src='/assets/images/distance.png'
                className='img-fluid w-24  py-3 m-auto '
                alt=''
              />
              <h4 className='text-xl text-center'>
                Cost, Payment & Cancellation Policy
              </h4>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 relative'>
            <div className='serve-card relative'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[90%] top-[-50px] rounded-[30%]' />

              <img
                src='/assets/images/road-1.png'
                className='img-fluid w-24  py-3 m-auto '
                alt=''
              />
              <h4 className='text-xl text-center'>
                Insurance & Personal Items
              </h4>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 relative'>
            <div className='serve-card relative'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[90%] top-[-50px] rounded-[30%]' />

              <img
                src='/assets/images/c3.png'
                className='img-fluid w-24  py-3 m-auto '
                alt=''
              />
              <h4 className='text-xl text-center'>Pickup & Delivery</h4>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 relative'>
            <div className='serve-card relative'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[90%] top-[-50px] rounded-[30%]' />

              <img
                src='/assets/images/car.png'
                className='img-fluid w-24  py-3 m-auto '
                alt=''
              />
              <h4 className='text-xl text-center'>
                Types Of Car Shipping Services
              </h4>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 relative'>
            <div className='serve-card relative'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[90%] top-[-50px] rounded-[30%]' />

              <img
                src='/assets/images/car-1.png'
                className='img-fluid w-32 m-auto '
                alt=''
              />
              <h4 className='text-xl text-center'>Useful Information</h4>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div className='serve-card'>
              <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[90%] top-[-50px] rounded-[30%]' />

              <div className='w-24 h-24 rounded-full bg-slate-900 my-3 flex items-center justify-center m-auto'>
                <BsFillTelephoneFill className='text-5xl text-white' />
              </div>
              <h4 className='text-xl text-center'>
                Call for inquiry --- ---- ----
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
