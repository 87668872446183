/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { FaRegUser } from "react-icons/fa";
import { BsFillTelephoneFill, BsHeadphones } from "react-icons/bs";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  const { pathname } = useLocation();
  useEffect(() => {
    // make it as accordion for smaller screens
    if (window.innerWidth > 992) {
      document.querySelectorAll(".navbar .nav-item").forEach(everyitem => {
        everyitem.addEventListener("mouseover", function (e) {
          let el_link = this.querySelector("a[data-bs-toggle]");

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.add("show");
            nextEl.classList.add("show");
          }
        });

        everyitem.addEventListener("mouseleave", function (e) {
          let el_link = this.querySelector("a[data-bs-toggle]");

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.remove("show");
            nextEl.classList.remove("show");
          }
        });
      });
    }
  }, []); // Empty dependency array ensures useEffect runs once after initial render

  return (
    <>
      <div className='d-flex justify-content-between align-items-center flex-wrap nav_top_main gap-2 relative'>
        <div className='top_txt'>
          <marquee>
            Easy Auto Trans Auto Trans has safely shipped 1,000,000+ vehicles to all
            50 states. Read our 80,000+ online reviews to hear more about our
            5-star customer service.
          </marquee>
        </div>
        <div className='py-2 d-flex gap-4 mx-3'>
          <div className='nav_top'>
            <BsHeadphones size={22} color='#1A2741' className='mx-2' />
            <p>Check My order</p>
          </div>
          <div className='nav_top'>
            <BsFillTelephoneFill color='#1A2741' className='mx-2' />
            <p>--- ---- ----</p>
          </div>
        </div>
      </div>
      <header className=' sticky top-0 z-50'>
        <nav
          // className=''
          className={` ${
            scrolling
              ? "navbar navbar-expand-lg  "
              : "navbar navbar-expand-lg  "
          }`}
          style={{ zIndex: "1" }}
          id='navbar_top'>
          <div className='container-fluid'>
            <Link className='navbar-brand' to='/'>
              <img
                src='assets/images/logo1.png'
                className='img-fluid '
                style={{ width: "80%" }}
                alt='logo'
              />
            </Link>
            <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'>
              <span className='navbar-toggler-icon' />
            </button>
            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'>
              <ul className='navbar-nav ms-auto align-items-center text-center mb-2  mb-lg-0'>
                <li className='nav-item '>
                  <Link
                    onClick={scrollToTop}
                    className={`nav-link myNav ${
                      pathname === "/" ? "active" : ""
                    }`}
                    aria-current='page'
                    to='/'>
                    Home
                  </Link>
                </li>
                <li className='nav-item dropdown'>
                  <Link
                    className={`nav-link dropdown-toggle `}
                    to='how-it-works'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'>
                    How it works
                  </Link>
                  <ul className='dropdown-menu'>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          pathname === "/how-it-works" ? "active" : ""
                        }`}
                        to='how-it-works'>
                        How our Works
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          pathname === "/car-shipping-costs" ? "active" : ""
                        }`}
                        to='car-shipping-costs'>
                        Car Shipping Cost
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          pathname === "/cross-country" ? "active" : ""
                        }`}
                        to='cross-country'>
                        Cross Country Vehicle Shipping
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li className='nav-item'>
                  <Link className='nav-link' to='#'>
                    For Individuals
                  </Link>
                </li> */}
                <li className='nav-item'>
                  <Link
                    className={`nav-link myNav ${
                      pathname === "/our-services" ? "active" : ""
                    }`}
                    to='our-services'>
                    Our services
                  </Link>
                </li>
                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle'
                    href='#!'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'>
                    Why Us
                  </a>
                  <ul className='dropdown-menu'>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          pathname === "/faqs" ? "active" : ""
                        }`}
                        to='faqs'>
                        Faqs
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          pathname === "/reviews" ? "active" : ""
                        }`}
                        to='reviews'>
                        Reviews
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          pathname === "/press" ? "active" : ""
                        }`}
                        to='press'>
                        Press
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`dropdown-item ${
                          pathname === "/vision-mission" ? "active" : ""
                        }`}
                        to='vision-mission'>
                        Vision and mission
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className='nav-item'>
                  <Link
                    className={`nav-link myNav ${
                      pathname === "/help" ? "active" : ""
                    }`}
                    to='help'>
                    Help
                  </Link>
                </li>
                <li className='nav-item '>
                  <Link
                    to='get-a-quote'
                    className='nav_btn flex items-center'
                    onClick={scrollToTop}>
                    <FaRegUser className='mx-1' /> Get a Quote
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
