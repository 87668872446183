/** @format */

import React from "react";
import ReactStars from "react-rating-stars-component";

const data = [
  { id: 1, name: "Google", rating: 4.6, image: "assets/images/s1.png" },
  { id: 2, name: "YELP", rating: 4.4, image: "assets/images/s2.png" },
  {
    id: 3,
    name: "Transport Reviews",
    rating: 4.8,
    image: "assets/images/s3.png",
  },
  {
    id: 4,
    name: "My Moving Reviews",
    rating: 4.3,
    image: "assets/images/s4.png",
  },
];

const Sections = () => {
  const ratingChanged = newRating => {
    console.log(newRating);
  };

  return (
    <>
      <div className='section_sec'>
        <div className='container'>
          <div className='row'>
            {data.map(res => {
              return (
                <div className='col-md-3 mt-3' key={res.id}>
                  <div className='card '>
                    <div className='d-flex justify-content-center align-items-center'>
                      <h5>{res.rating}</h5>
                    </div>
                    <div className='d-flex flex-col justify-content-center align-items-center'>
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={20}
                        value={4.7}
                        isHalf={true}
                        emptyIcon={<i className='far fa-star'></i>}
                        halfIcon={<i className='fa fa-star-half-alt'></i>}
                        fullIcon={<i className='fa fa-star'></i>}
                        activeColor='#ffd700'
                      />
                      <img src={res.image} className='img-fluid mt-3' alt='' />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sections;
