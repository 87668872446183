/** @format */

import React, { useEffect } from "react";
import Hero from "../components/Elements/Hero";
import About from "../components/Elements/About";
import Company from "../components/Elements/Company";
import Shipping from "../components/Elements/Shipping";
import Sections from "../components/Elements/Sections";
import Transport from "../components/Elements/Transport";
import Auto from "../components/Elements/Auto";
import Stories from "../components/Elements/Stories";
import Review from "../components/Elements/Review";
import Banner from "../components/Elements/Banner";
import OutSource from "../components/Elements/OutSource";
import Faqs from "../components/Utils/Faqs";
import { BsArrowUpShort } from "react-icons/bs";
import { animateScroll as scroll } from "react-scroll";

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth", });
  };
  const scrollUp = () => {
    const scrollUpElement = document.querySelector('#scroll-up');
    if (scrollUpElement) {
      if (window.scrollY >= 560) {
        scrollUpElement.classList.add('show-scroll');
      } else {
        scrollUpElement.classList.remove('show-scroll');
      }
    }
  };

  const scrollHeader = () => {
    const nav = document.querySelector("#header");
    if (nav) {
      if (window.scrollY >= 60) {
        nav.classList.add("scroll-sc");
      } else {
        nav.classList.remove("scroll-sc");
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollUp);
    window.addEventListener('scroll', scrollHeader);

    return () => {
      window.removeEventListener('scroll', scrollUp);
      window.removeEventListener('scroll', scrollHeader);
    };
  }, []);
  return (
    <>
      <Hero />
      <About />
      <OutSource />
      {/* <Company/> */}
      <Shipping />
      <Sections />
      <Transport />
      <Auto />
      {/* <Stories /> */}
      {/* <Review /> */}
      <Faqs />
      <Banner />
      <span onClick={scrollToTop} className='scrollup z-50' id='scroll-up'>
        <BsArrowUpShort className=' scrollup_icon' />
      </span>
    </>
  );
};

export default Home;
