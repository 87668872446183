/** @format */

import { useState } from "react";

const Faqs = () => {
  const [faqState, setFaqState] = useState({
    openFaq1: false,
    openFaq2: false,
    openFaq3: false,
    openFaq4: false,
    openFaq5: false,
    openFaq6: false,
  });

  const toggleFaq = faqKey => {
    setFaqState(prevState => ({
      ...prevState,
      [faqKey]: !prevState[faqKey],
    }));
  };
  return (
   
    <>
      <section className='relative z-20 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] '>
        <div className='container mx-auto'>
          <div className='-mx-4 flex flex-wrap'>
            <div className='w-full px-4'>
              <div className='mx-auto mb-[60px] max-w-[480px] text-center lg:mb-20'>
                <span className='mb-2 block text-lg font-semibold text-[#1a2741]'>
                  FAQ
                </span>
                <h2 className='mb-4 text-3xl font-bold text-dark dark:text-white sm:text-[40px]/[48px]'>
                  Any Questions? Look Here
                </h2>
                <p className='text-base text-body-color dark:text-dark-6'>
                  We also offer discount car shipping services so don’t hesitate
                  to ask if you qualify for one.
                </p>
              </div>
            </div>
          </div>

          <div className='-mx-4 flex flex-wrap'>
            {[
              {
                title: "How do I pay for my transport service?",
                content: `You can choose to pay in full online as we accept all major credit cards, Zelle and Cash App.. You can also pay in cash at the time of delivery. If you choose to pay in cash, there is a small partial payment of $199 or less (depending on the distance of your shipment) when you place your order to secure your car’s spot on a truck.
        `,
              },
              {
                title: "Can you guarantee a pick up or delivery date and time?",
                content: `Guarantee pickup dates…Yes, we can! All you need to do is decide whether you need the car picked up by a specific date or on a specific date, which will determine the extra cost involved to guarantee your time frame. Please call for a customized quote if you need guaranteed pick up date(s). However, because anything can happen on the road, the auto-hauling industry does not guarantee delivery dates.`,
              },

              {
                title: "What is the First Available Shipping Date?",
                content: `This is the date your vehicle is ready to be picked up. However, this is not necessarily the exact pick up date. Depending on truck availability at that particular time, we may be able to assign a truck to load your vehicle on your First Available Shipping Date but it may also be a few days after. If we cannot pick it up that day, it should be within our usual pick up window of 1 to 5 business days starting from that date. For more details see pick up date and time.`,
              },

              {
                title: "What is Door-to-Door auto transport?",
                content: `This means that the truck driver will pick the vehicle up and drop it off as close to your home as he can legally and safely get. Many cities have restrictions on large trucks that prohibit them from driving into some residential areas. Transport trucks are enormous and need a lot of room to maneuver and turn around, so if access to your home is restricted by narrow streets, low-hanging trees, speed bumps or tight turns the driver may ask that you meet the truck at a large parking lot nearby such as a grocery store. You will receive a call directly from the trucker if these alternative arrangements are necessary.
        `,
              },
              {
                title: "Is there any addition costs ?",
                content: `No, the quote is all inclusive. It covers door to door service, full bumper to bumper insurance, all tax and tolls paid, and personal belongings up to 100 lbs free of charge.
        `,
              },
              {
                title: "What payment methods are available on delivery?",
                content: `Drivers are not allowed to accept credit cards or person checks on delivery. If you have an outstanding balance that is due on delivery, please make sure that you have cash, cashier’s check, or money order.
        `,
              },
            ].map((faqNum, id) => (
              <div key={id} className={`w-full px-4 lg:w-1/2`}>
                <div className='mb-8 w-full rounded-lg bg-white p-4 shadow-[0px_20px_95px_0px_rgba(201,203,204,0.30)] dark:bg-dark-2 dark:shadow-[0px_20px_95px_0px_rgba(0,0,0,0.30)] sm:p-8 lg:px-6 xl:px-8'>
                  <span
                    className='faq-btn flex w-full text-left cursor-pointer'
                    onClick={() => toggleFaq(`openFaq${id}`)}>
                    <div className='mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-[#1a27410e] text-[#1a2741] dark:bg-white/5'>
                      <svg
                        className={faqState[`openFaq${id}`] && "rotate-180"}
                        width='22'
                        height='22'
                        viewBox='0 0 22 22'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M11 15.675C10.7937 15.675 10.6219 15.6062 10.45 15.4687L2.54374 7.69998C2.23436 7.3906 2.23436 6.90935 2.54374 6.59998C2.85311 6.2906 3.33436 6.2906 3.64374 6.59998L11 13.7844L18.3562 6.53123C18.6656 6.22185 19.1469 6.22185 19.4562 6.53123C19.7656 6.8406 19.7656 7.32185 19.4562 7.63123L11.55 15.4C11.3781 15.5719 11.2062 15.675 11 15.675Z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                    <div className='w-full'>
                      <h4 className='mt-1 text-lg font-semibold text-dark dark:text-white'>
                        {faqNum.title}
                      </h4>
                    </div>
                  </span>
                  {faqState[`openFaq${id}`] && (
                    <div className='faq-content pl-[62px]'>
                      <p className='py-3 text-base leading-relaxed text-body-color dark:text-dark-6'>
                        {faqNum.content}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='absolute bottom-0 right-0 z-[-1]'>
          <svg
            width='1440'
            height='886'
            viewBox='0 0 1440 886'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              opacity='0.5'
              d='M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z'
              fill='url(#paint0_linear)'
            />
            <defs>
              <linearGradient
                id='paint0_linear'
                x1='1308.65'
                y1='1142.58'
                x2='602.827'
                y2='-418.681'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='#3056D3' stopOpacity='0.36' />
                <stop offset='1' stopColor='#F5F2FD' stopOpacity='0' />
                <stop offset='1' stopColor='#F5F2FD' stopOpacity='0.096144' />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </section>
    </>
  );
};

export default Faqs;
