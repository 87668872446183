/** @format */

import React from "react";
import Sections from "../../Elements/Sections";
import ReactStars from "react-rating-stars-component";
import { FaArrowDown } from "react-icons/fa";
import { AiTwotoneStar } from "react-icons/ai";
const Reviews = () => {
  const ratingChanged = newRating => {
    console.log(newRating);
  };
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <h1>
              Why Choose Easy Auto Trans <br />
              Auto Transportation
            </h1>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='about_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 m-auto '>
              <h4>Easy Auto Trans Auto Trans Reviews</h4>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-5 m-auto'>
              <p>
                We want our customers to be fully satisfied and nothing makes us
                happier than getting positive car transport reviews.
              </p>
            </div>
            <div className='col-md-3'>
              <div className='relative text-center m-auto'>
                <h1 className=' text-8xl text-white text-center'>4.7</h1>
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  classNames='w-50 m-auto pl-4'
                  size={25}
                  value='4.7'
                  isHalf={true}
                  emptyIcon={<i className='far fa-star'></i>}
                  halfIcon={<i className='fa fa-star-half-alt'></i>}
                  fullIcon={<i className='fa fa-star'></i>}
                  activeColor='#ffd700'
                />
                <p className='text-white sm:w-9/12 w-fill m-auto '>
                  {" "}
                  Our reputation from auto transporter reviews
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <Sections />
      {/*  */}
      <div className='review_sec1'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-6 m-auto' style={{ padding: "0%" }}>
              <div className='review_left'>
                <h3>
                  Read What Other Customers Say About Their Experience <br />
                  In Their Easy Auto Trans Auto Trans Reviews
                </h3>
                <p>
                  Or Call On Operations <span>(832) 521-7348</span>
                </p>
                <div className='d-flex justify-content-center align-items-center'>
                  <AiTwotoneStar color='#f0ad4e' size={20} />
                  <AiTwotoneStar color='#f0ad4e' size={20} />
                  <AiTwotoneStar color='#f0ad4e' size={20} />
                  <AiTwotoneStar color='#f0ad4e' size={20} />
                  <AiTwotoneStar color='#f0ad4e' size={20} />
                </div>

                <div
                  id='carouselExampleControls'
                  className='carousel slide my-5'
                  data-bs-ride='carousel'>
                  <div className='carousel-inner'>
                    <div className='carousel-item active'>
                      <h5>
                        This is a great service and John Harrison was a great
                        help! The entire process was expedited in six
                        days/Florida to Pennsylvania. John kept in touch with me
                        every step of the way. I will definitely use this
                        service again if the need ever arises. Bob Graffin New
                        Freedom, PA
                      </h5>
                    </div>
                    <div className='carousel-item '>
                      <h5>
                        I had to transport a car for the first time in my life.
                        Mr. John Harrison assisted me through this process
                        patiently and impeccably. What I found is that there
                        really isn't too much of a spread in the cost you will
                        pay, what matters in the end is the quality of
                        communication (the drivers are professionals, they know
                        what they're doing). Our car had to go coast-to-coast
                        and it got there faster and for less money than it if we
                        took a 1-week vacation and drove it ourselves, and flew
                        back. Thank you Mr Harrison for the good service!
                      </h5>
                    </div>
                    <div className='carousel-item '>
                      <h5>
                        Got my car shift on time, these guys are pro in there
                        work, highly professional, I will definitely hire them
                        again!! Kevin helped me a lot and get the work done in a
                        affordable price!! Highly Recommended!
                      </h5>
                    </div>
                    <div className='carousel-item '>
                      <h5>
                        I would highly recommend Easy Auto Trans for your auto
                        transport. I used them to ship my bmw i3 from Jackson,
                        MS to Herndon, VA and they got it done next day! I was
                        wowed. My baby was in the same condition as when she
                        left. I worked with John Harrison and he ensured that I
                        was able to get a really good rate, especially compared
                        to others who were charging in the 1000s. I was nervous
                        at first because of all the scams out there but this
                        company, while young, got the job done quickly and
                        affordably. Thank you guys!
                      </h5>
                    </div>
                  </div>
                  <button
                    className='carousel-control-prev text-dark'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'>
                    <span
                      className='carousel-control-prev-icon bg-dark rounded-full'
                      aria-hidden='true'
                    />
                    <span className='visually-hidden'>Previous</span>
                  </button>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'>
                    <span
                      className='carousel-control-next-icon bg-dark rounded-full right-12' 
                      aria-hidden='true'
                    />
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='container my-5'>
        <div className='mid-banner mt-5 relative'>
          <div class='background-overlay'></div>
          <div className='container'>
            <div className='inner flex justify-around items-center'>
              <div>
                <h1>Ready To Transport Your Car With Easy Auto Trans?</h1>
                <p>
                  Our car shipping advisors are available by calling (832)
                  521-7324 to answer all your questions.
                </p>
              </div>
              <div>
                <button className=' flex items-center'>
                  <FaArrowDown className='mx-1' /> Get a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
