/** @format */

import React from "react";
import Auto from "../../Elements/Auto";
import Banner from "../../Elements/Banner";

const Press = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <div>
              <h1>Press</h1>
              <p className='sm:w-4/12 w-full'>
                Press The leader in the U.S. auto transport industry, Easy Auto Trans
                Auto Transport has built its reputation by delivering superior
                customer service to individuals as well as corporate clients in
                the dealership, moving and relocation, auto auction.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Auto />
      <Banner />
    </>
  );
};

export default Press;
