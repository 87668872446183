/** @format */

import React from "react";
import { FaArrowDown } from "react-icons/fa";

const CarShipping = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);

  return (
    <>
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <div>
              <h1>How Auto Transportation Works</h1>
              <p>Put The Power Of An Auto Relocation Leader To Work For You</p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
            <div className='col-md-6 py-5'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
                <h5>Shipping Cost</h5>
              </div>
              <h2 className='mt-5'>How Much Does Car Shipping Cost?</h2>
              <p className='mt-5'>
                To figure out how much it will cost to transport your car, you
                can request a quote from a carrier or broker. Short distance
                trips (1-500 miles) could cost around $1.96 per mile, while
                prices for medium distances (500-1500 miles) are approximately
                $.93 per mile and long distances (1500+ miles) usually average
                around $.58 per mile. If you have any questions, our car
                shipping advisors can be reached at (888) 666-8929 or you can
                get an instant quote by clicking here.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 py-5'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
                <h5>Car Transport</h5>
              </div>
              <h2 className='mt-5'>What Is Car Transport?</h2>
              <p className='mt-5'>
                Vehicle transport is a service that transports automobiles from
                one place to another. Open transport, which is typically more
                economical, and enclosed transport, which offers more protection
                for vehicles, are the two main options available. Generally
                speaking, enclosed auto transport costs around 30-40% more than
                open transportation.
              </p>
            </div>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
            <div className='col-md-6 py-5'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
                <h5>Transport Quote</h5>
              </div>
              <h2 className='mt-3'>How Do I Get A Car Transport Quote?</h2>
              <p className='mt-3'>
                The cost of car transport can be figured out in various ways. It
                is possible to determine the price online through the transport
                firm’s website or by simply phoning them. To arrange a fast pick
                up for your vehicle, make sure you let the company know ahead of
                time.
              </p>
              <p className='mt-3'>
                For the most precise and up-to-date estimates of car shipping
                costs, it is important to have the following information
                available:
              </p>

              <ul
                className='mt-8  text-sm leading-6 text-gray-600 '>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Vehicle pick up and delivery destination (zip codes are
                  preferred for increased quoting accuracy)
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Car transport preference (open or enclosed trailer)
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Vehicle year, make, and model (ensures availability of
                  required transport trailer size)
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Operational status (can be driven forward and in reverse, with
                  basic steering for truck loading and unloading)
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Earliest available car pick-up date
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32'>
        <img
          src='https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply'
          alt=''
          className='absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center'
        />
        <div
          className='hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl'
          aria-hidden='true'>
          <div
            className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20'
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className='absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu'
          aria-hidden='true'>
          <div
            className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20'
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto  lg:mx-0'>
            <h2 className='text-4xl font-bold tracking-tight text-white sm:text-5xl'>
              Overview Of The Auto Transport Industry
            </h2>
            <p className='mt-6 text-lg leading-8 text-gray-300'>
              You might work with carriers, brokers, or lead providers when
              shipping your vehicle.
            </p>
            <p className='mt-6 text-lg leading-8 text-gray-300'>
              Lead providers give car owners a selection of “leads,” which are
              possible carriers, that matches their specifications. To find the
              best choice for their need and spending plan, the owner must do
              more research or contact the carriers on the list.
            </p>
            <p className='mt-6 text-lg leading-8 text-gray-300'>
              By using a broker, you can benefit from having access to thousands
              of carriers and finding the best option that meets your needs and
              budget. You don’t need to make multiple calls or search different
              websites; brokers handle the communication with the carrier and do
              the legwork for you. This allows you to save time and energy while
              still ensuring your car is transported safely.
            </p>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 py-5'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
                <h5>Calculating</h5>
              </div>
              <h2 className='mt-5'>
                Calculating My Car Shipping Estimate And Auto Shipper Math
              </h2>
              <p className='mt-5'>
                The cost of auto transport is based on several considerations
                specific to each customer. These factors, such as the distance
                of the trip, type and size of vehicle, condition of vehicle
                (working or not working), type of transport (open or enclosed)
                and location (city/rural) as well as seasonality can be taken
                into account when calculating auto transport quotations. With
                Easy Auto Trans’s car shipping calculator you can enter all necessary
                details about your car for a tailored price estimate without any
                hidden fees.
              </p>
            </div>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='container-fluid bg-slate-200 py-5 serve'>
        <h2 className='text-center text-[36px]'>
          What Factors Are Used To Calculate Cost Of Car Shipping?
        </h2>
        <div className='container'>
          <div className='row mt-5'>
            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/car-1.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Distance</h4>
                <p>
                  Total distance is the main component used in calculating a
                  quote for car shipping. Longer distances generally lead to a
                  higher overall car shipping price, but also include a
                  discounted cost per mile.
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/review.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Route</h4>
                <p>
                  Different from distance, route refers to what highways and
                  other roads will be traveled to transport the vehicle. Routes
                  in highly populated areas may have increased traffic
                  congestion, which could impact transport time and shipping
                  costs.
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/service.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Shipping Date</h4>
                <p>
                  When you book a car transport service, shipment date often
                  plays a huge role in the cost. It’s best to book your car
                  shipment as far in advance as possible. As soon as you know
                  you’ll need to ship your vehicle
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/car-1.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Vehicle Make & Model</h4>
                <p>
                  When planning your auto transport, we ask for the exact
                  vehicle specifications, because the truck driver must know how
                  much space is required to safely secure your automobile and
                  ensure proper trailer height and side clearance.
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/car-1.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Auto Transport Type</h4>
                <p>
                  Most people shipping a car select open car transport, as it is
                  the industry standard and cheapest way for car shippers to
                  move your vehicle. For extra car shipment protection, we
                  recommend using enclosed car transportation services that
                  shield vehicles from weather and debris.
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/car-1.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Vehicle Condition</h4>
                <p>
                  If your car is running, you will get a better auto shipping
                  price from car shippers for the allotted distance. If your car
                  is inoperable, but maintains the ability to roll, steer, and
                  brake, it will require a winch and other accommodations to be
                  loaded onto the carrier trailer.
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/car-1.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Vehicle Details</h4>
                <p>
                  Certain characteristics of your car will have an impact on the
                  quote and final cost of your car shipment
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/car-1.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Location</h4>
                <p>
                  Metropolitan areas usually have plenty of trucks available to
                  carry out car shipping orders for auto shippers, which lowers
                  the car transport price. When transporting cars, we strive to
                  find the best deal for residents of small towns.
                </p>
              </div>
            </div>

            <div className='col-md-6 col-lg-4 col-sm-12'>
              <div className='serve-card relative overflow-hidden text-center'>
                {" "}
                <span className='block w-[123px] h-[101px] bg-[#1a2741] absolute left-[95%] top-[-50px] rounded-[30%]' />
                <img
                  src='/assets/images/car-1.png'
                  className='img-fluid w-32 m-auto py-4'
                  alt=''
                />
                <h4>Seasonality</h4>
                <p>
                  Car shipping costs vary greatly based on the time of year. For
                  example, auto transport services to warmer states are very
                  popular during the snowbird season, so the cost for
                  transporting a car to those destinations will be higher.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
            <div className='col-md-6 py-5'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
                <h5>Insurance</h5>
              </div>
              <h2 className='mt-3'>Auto Transport Insurance</h2>
              <p className='mt-3'>
                Carriers of auto transport are obligated to have liability
                insurance. Getting extra cargo insurance is not mandatory, so
                many carriers do not provide it. An auto shipping broker can
                help you get the cargo insurance you need and select the right
                coverage. Make sure to ask them these questions when deciding on
                an insurance plan:
              </p>

              <ul
           
                className='mt-8  text-sm leading-6 text-gray-600 '>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  What type of damage is covered?
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Does insurance cover the entire vehicle, or only specific
                  parts?
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Is there a deductible?
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  What is the limit of coverage?
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Are specialty items or accessories covered?
                </li>
                <li className='flex gap-x-3 mt-3'>
                  <svg
                    className='h-6 w-5 flex-none text-slate-800 '
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'>
                    <path
                      fillRule='evenodd'
                      d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Is there an option to purchase additional coverage?
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <div>
              <h1>Ready To Get A Car Shipping Quote With Easy Auto Trans?</h1>
              <p>
                Use the calculator below or call us at --- ---- ---- today!
              </p>
              <div>
                <button className='flex items-center'>
                  <FaArrowDown className='mx-1' /> Get a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarShipping;
