/** @format */

import React from "react";
import { FaArrowDown } from "react-icons/fa";

const HowItWork = () => {
  React.useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    scrollToTop();
  }, []);
  return (
    <>
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <div>
              <h1>How Auto Transportation Works</h1>
              <p>Put The Power Of An Auto Relocation Leader To Work For You</p>
            </div>
          </div>
        </div>
      </div>
      <div className='transport_sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='d-flex justify-content-start  align-items-center gap-2'>
                <div className='border_top'></div>
                <h5>How Does Auto Transport Work?</h5>
              </div>
              <h2 className='mt-5'>
                This Easy Guide Will Show <br />
                <span>You In 3 Simple Steps.</span>
              </h2>
              <p className='mt-5'>
                We understand that your car holds a special place in your heart,
                and unlike other companies, we are enthusiastic about providing
                you with all the information you need about auto transport. Our
                goal is to offer you top-notch auto transport services at an
                affordable price, whether you’re embarking on a new journey or
                buying/selling a car online. With a 5-star rating, Easy Auto Trans is
                the perfect choice for your auto transport needs.
              </p>
            </div>
            <div className='col-md-6'>
              <div className='imgs'></div>
              {/* <img src="assets/images/6.jpg" className="img-fluid rounded-3" alt="" /> */}
            </div>
          </div>
        </div>
      </div>

      {/* ====================== */}
      <div className='work_sec'>
        <div className='container'>
          <div className='row main_card'>
            <div className='col-md-8'>
              <div className='content'>
                <div
                  className='wrapper'
                  data-v-097722ea
                  contentEditable='false'>
                  <div
                    className='label'
                    data-v-097722ea
                    contentEditable='false'>
                    <span data-v-097722ea contentEditable='false'>
                      1
                    </span>
                  </div>
                  <span
                    className='line'
                    data-v-097722ea
                    contentEditable='false'
                  />
                </div>
                <div className='left_txt'>
                  <h4>Quote And Book Your Order</h4>
                  <p>
                    For an instant online quote in seconds, click on the link or
                    tap here. Alternatively, contact our advisors by phone or
                    chat and compare our services and prices for yourself—you
                    won’t be disappointed. Our prices are highly competitive!
                    Once you’re ready to book with us, just click the link from
                    your email or on our page quote, start a chat with us, or
                    call (888) 666-8929 and we’ll arrange for one of our 40,000+
                    insured and vetted professional truckers to pick up your
                    vehicle. We won’t charge you anything until the pickup is
                    scheduled! For more information about financing options and
                    payment security measures, simply open a chat window with
                    one of our advisors who will be happy to help.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4' style={{ padding: "0px" }}>
              <div className='visual'>
                <div className='right_img'>
                  <img
                    src='https://capstoneautotrans.com/wp-content/uploads/2023/01/step_1_final-1024x978-1.jpg'
                    className='img-fluid'
                    alt=''
                  />
                  {/* <div className="top_img">
                        <img src=""  alt="" />
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 main_card'>
            <div className='col-md-8'>
              <div className='content'>
                <div
                  className='wrapper'
                  data-v-097722ea
                  contentEditable='false'>
                  <div
                    className='label'
                    data-v-097722ea
                    contentEditable='false'>
                    <span data-v-097722ea contentEditable='false'>
                      2
                    </span>
                  </div>
                  <span
                    className='line'
                    data-v-097722ea
                    contentEditable='false'
                  />
                </div>
                <div className='left_txt'>
                  <h4>We Pick Up Your Vehicle</h4>
                  <p>
                    Arrange a pickup date, time and location that is convenient
                    for you with your trucker. You can even have your car picked
                    up while you are away at work. Once the trucker arrives, you
                    and he will go over your car together and sign a Bill of
                    Lading. Then say your goodbyes, pack up and leave everything
                    else to us. We will make sure that your vehicle is handled
                    with care; moreover, you can keep track of its progress
                    through our online chat or by calling the driver directly.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4' style={{ padding: "0px" }}>
              <div className='visual'>
                <div className='right_img'>
                  <img
                    src='https://capstoneautotrans.com/wp-content/uploads/2023/01/step_2_final-992x1024-1.jpg'
                    className='img-fluid w-100'
                    alt=''
                  />
                  <div className='top_img2'>
                    {/* <img src='https://capstoneautotrans.com/wp-content/uploads/2023/01/step_2_final-992x1024-1.jpg' alt='' /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-5 main_card'>
            <div className='col-md-6'>
              <div className='content'>
                <div
                  className='wrapper'
                  data-v-097722ea
                  contentEditable='false'>
                  <div
                    className='label'
                    data-v-097722ea
                    contentEditable='false'>
                    <span data-v-097722ea contentEditable='false'>
                      3
                    </span>
                  </div>
                </div>
                <div className='left_txt'>
                  <h4>Receive Your Vehicle</h4>
                  <p>
                    We look forward to the joyous occasion of you being reunited
                    with your vehicle!
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6' style={{ padding: "0px" }}>
              <div className='visual'>
                <div className='right_img3'>
                  <img
                    src='https://capstoneautotrans.com/wp-content/uploads/2023/01/step_3_final-992x1024-1.jpg'
                    className='img-fluid'
                    alt=''
                  />
                  {/* <div className='top_img3'>
                    <img src='assets/images/p3.webp' alt='' />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid header-banner position-relative'>
        <div class='background-overlay'></div>
        <div className='container'>
          <div className='inner'>
            <div>
              <h1>Ready To Get A Car Shipping Quote With Easy Auto Trans?</h1>
              <p>
                Use the calculator below or call us at --- ---- ---- today!
              </p>
              <div>
                <button className='flex items-center'>
                  <FaArrowDown className='mx-1' /> Get a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWork;
