/** @format */

import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";

const Shipping = () => {
  return (
    <>
      <div className='shipping_sec text-center'>
        <div className='container'>
          <div className=" backdrop-blur-sm max-w-xl m-auto">
            <h2>
              Ready To Get A Car Shipping <br /> Quote With Easy Auto Trans?
            </h2>
            <p>
              Use the calculator below or call us at <span>--- ---- ----</span>{" "}
              today!
            </p>
            <div className='btn_main '>
              <button className='flex items-center m-auto'>
                Get a Quote <AiOutlineArrowDown size={20} className='mx-1' />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipping;
