import React from "react";

const Auto = () => {
  return (
    <>
      <div className="auto_sec">
        <div className="container">
          <h2>Welcome To Easy Auto Trans Auto Trans</h2>
          <p>
            If you’re an individual or a business needing car shipping services,
            you can get an immediate, door-to-door auto transport cost through
            our online rate <br /> calculator or by calling us 24/7.
          </p>
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card">
                <h5>200,000 +</h5>
                <h3>CUSTOMERS SERVED</h3>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card">
                <h5>437,000 +</h5>
                <h3>VEHICLES SHIPPED</h3>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card">
                <h5>10,000 +</h5>
                <h3>ONLINE REVIEWS</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auto;
