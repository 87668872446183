/** @format */

import axios from "axios";
import React, { useState } from "react";

const GetAQuote = () => {
  const [formData, setFormData] = useState({
    fromZip: "",
    toZip: "",
    transportType: "Enclosed",
    vehicleYear: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleCondition: "",
    pickupDate: "",
    fullName: "",
    phone: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    fromZip: "",
    toZip: "",
    vehicleYear: "",
    vehicleMake: "",
    vehicleModel: "",
    pickupDate: "",
    fullName: "",
    phone: "",
    email: "",
  });

  const [currentStep, setCurrentStep] = useState(1);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleRadioChange = e => {
    setFormData({
      ...formData,
      transportType: e.target.value,
    });
  };

  const handleNextClick = () => {
    if (currentStep === 1) {
      const newErrors = {
        fromZip: formData.fromZip.trim() === "" ? "This field is required" : "",
        toZip: formData.toZip.trim() === "" ? "This field is required" : "",
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some(error => error !== "")) {
        return;
      }
    } else if (currentStep === 2) {
      const newErrors = {
        vehicleYear:
          formData.vehicleYear.trim() === "" ? "This field is required" : "",
        vehicleMake:
          formData.vehicleMake.trim() === "" ? "This field is required" : "",
        vehicleModel:
          formData.vehicleModel.trim() === "" ? "This field is required" : "",
        vehicleCondition:
          formData.vehicleCondition.trim() === ""
            ? "This field is required"
            : "",
        pickupDate:
          formData.pickupDate.trim() === "" ? "This field is required" : "",
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some(error => error !== "")) {
        return;
      }
    }

    setCurrentStep(currentStep + 1);
  };

  const handlePreviousClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [placeholder, setPlaceholder] = useState("Vehicle Condition");
  const options = [
    { value: "Operable", label: "Operable" },
    { value: "InOperable", label: "InOperable" },
  ];

  const handleOptionClick = (value, label, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    const newErrors = {
      ...errors,
      [name]: value.trim() === "" ? "This field is required" : "",
    };

    setErrors(newErrors);
    setPlaceholder(label);
    setIsOpen(false);
  };
  const handleSelectChange = e => {
    handleOptionClick(e.target.value, e.target.value, "vehicleCondition");
  };
  const handleSubmit = async e => {
    e.preventDefault();

    // Validate additional fields if they are visible
    if (currentStep === 3) {
      const newErrors = {
        fullName:
          formData.fullName.trim() === "" ? "This field is required" : "",
        phone: formData.phone.trim() === "" ? "This field is required" : "",
        email: formData.email.trim() === "" ? "This field is required" : "",
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some(error => error !== "")) {
        return;
      }
    }
    setLoading(true);
    const response = await axios.post(
      "https://Easy Auto Transapi.onrender.com/send-order",
      formData,
    );

    if (response.status === 200) {
      console.log("Form submitted successfully!");
      setSuccessMessage("Form submitted successfully!");
      setLoading(false);

      // Optionally, you can reset the form or navigate to another page
    } else {
      console.error("Error submitting form:", response.statusText);
      setLoading(false);
    }
  };
  // ====================================================================================================

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        {currentStep === 1 && (
          <>
            <div className='mb-3'>
              <label htmlFor='exampleInputEmail1' className='form-label'>
                Transport car FROM <span>*</span>
              </label>
              <input
                type='number'
                className={`form-control ${errors.fromZip ? "is-invalid" : ""}`}
                placeholder='Zip Code'
                name='fromZip'
                value={formData.fromZip}
                onChange={handleInputChange}
              />
              {errors.fromZip && (
                <div className='invalid-feedback'>{errors.fromZip}</div>
              )}
            </div>

            <div className='mb-3'>
              <label htmlFor='exampleInputPassword1' className='form-label'>
                Transport car To <span>*</span>
              </label>
              <input
                type='number'
                className={`form-control ${errors.toZip ? "is-invalid" : ""}`}
                placeholder='Zip Code'
                name='toZip'
                value={formData.toZip}
                onChange={handleInputChange}
              />
              {errors.toZip && (
                <div className='invalid-feedback'>{errors.toZip}</div>
              )}
            </div>
            <div className='my-4'>
              <label htmlFor='exampleInputPassword1' className='form-label'>
                Transport type To <span>*</span>
              </label>
              <div className='d-flex gap-4'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault1'
                    value='Open'
                    onChange={handleRadioChange}
                  />
                  <label className='form-check-label'>Open</label>
                </div>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='flexRadioDefault'
                    id='flexRadioDefault2'
                    value='Enclosed'
                    onChange={handleRadioChange}
                    defaultChecked
                  />
                  <label className='form-check-label'>Enclosed</label>
                </div>
              </div>
            </div>
            <button
              type='button'
              className='btn mt-3 '
              onClick={handleNextClick}>
              Next
            </button>
          </>
        )}

        {currentStep === 2 && (
          <>
            <div className='mb-3'>
              <label htmlFor='exampleInputVehicleYear' className='form-label'>
                Vehicle Year <span>*</span>
              </label>
              <input
                type='number'
                className={`form-control ${
                  errors.vehicleYear ? "is-invalid" : ""
                }`}
                placeholder='Vehicle Year'
                name='vehicleYear'
                value={formData.vehicleYear}
                onChange={handleInputChange}
              />
              {errors.vehicleYear && (
                <div className='invalid-feedback'>{errors.vehicleYear}</div>
              )}
            </div>

            <div className='mb-3'>
              <label htmlFor='exampleInputVehicleMake' className='form-label'>
                Vehicle Make <span>*</span>
              </label>
              <input
                type='text'
                className={`form-control ${
                  errors.vehicleMake ? "is-invalid" : ""
                }`}
                placeholder='Vehicle Make'
                name='vehicleMake'
                value={formData.vehicleMake}
                onChange={handleInputChange}
              />
              {errors.vehicleMake && (
                <div className='invalid-feedback'>{errors.vehicleMake}</div>
              )}
            </div>

            <div className='mb-3'>
              <label htmlFor='exampleInputVehicleModel' className='form-label'>
                Vehicle Model <span>*</span>
              </label>
              <input
                type='text'
                className={`form-control ${
                  errors.vehicleModel ? "is-invalid" : ""
                }`}
                placeholder='Vehicle Model'
                name='vehicleModel'
                value={formData.vehicleModel}
                onChange={handleInputChange}
              />
              {errors.vehicleModel && (
                <div className='invalid-feedback'>{errors.vehicleModel}</div>
              )}
            </div>

            <div className='custom-select-wrapper mb-3'>
              <label
                htmlFor='exampleInputVehicleCondition'
                className='form-label'>
                Vehicle Condition <span>*</span>
              </label>
              <div
                className={`custom-select form-select ${
                  isOpen ? "opened" : ""
                } `}>
                <span
                  className={`custom-select-trigger ${
                    errors.vehicleCondition ? "is-invalid" : ""
                  }`}
                  onClick={() => setIsOpen(!isOpen)}>
                  {placeholder}
                </span>
                <div className='custom-options'>
                  {options.map((option, index) => (
                    <span
                      key={index}
                      className={`custom-option ${
                        errors.vehicleCondition === option.value
                          ? "selection"
                          : ""
                      }`}
                      data-value={option.value}
                      onClick={() =>
                        handleOptionClick(
                          option.value,
                          option.label,
                          "vehicleCondition",
                        )
                      }>
                      {option.label}
                    </span>
                  ))}
                </div>
              </div>
              <select
                name='vehicleCondition'
                value={errors.vehicleCondition}
                id='sources'
                required
                className={`form-select  custom-select sources ${
                  errors.vehicleCondition ? "is-invalid" : ""
                }`}
                onChange={handleSelectChange}
                style={{ display: "none" }}>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.vehicleCondition && (
                <div className='invalid-feedback'>
                  {errors.vehicleCondition}
                </div>
              )}
            </div>

            <div className='mb-3'>
              <label htmlFor='exampleInputPickupDate' className='form-label'>
                Pickup Date <span>*</span>
              </label>
              <input
                type='date'
                className={`form-control ${
                  errors.pickupDate ? "is-invalid" : ""
                }`}
                name='pickupDate'
                value={formData.pickupDate}
                onChange={handleInputChange}
              />
              {errors.pickupDate && (
                <div className='invalid-feedback'>{errors.pickupDate}</div>
              )}
            </div>

            <div>
              <button
                type='button'
                className='btn mt-3 '
                onClick={handlePreviousClick}>
                Previous
              </button>
              <button
                type='button'
                className='btn mt-3'
                onClick={handleNextClick}>
                Next
              </button>
            </div>
          </>
        )}

        {currentStep === 3 && (
          <>
            <div className='mb-3'>
              <label htmlFor='exampleInputFullName' className='form-label'>
                Full Name <span>*</span>
              </label>
              <input
                type='text'
                className={`form-control ${
                  errors.fullName ? "is-invalid" : ""
                }`}
                placeholder='Full Name'
                name='fullName'
                value={formData.fullName}
                onChange={handleInputChange}
              />
              {errors.fullName && (
                <div className='invalid-feedback'>{errors.fullName}</div>
              )}
            </div>

            <div className='mb-3'>
              <label htmlFor='exampleInputPhone' className='form-label'>
                Phone <span>*</span>
              </label>
              <input
                type='tel'
                className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                placeholder='Phone'
                name='phone'
                value={formData.phone}
                onChange={handleInputChange}
              />
              {errors.phone && (
                <div className='invalid-feedback'>{errors.phone}</div>
              )}
            </div>

            <div className='mb-3'>
              <label htmlFor='exampleInputEmail' className='form-label'>
                Email <span>*</span>
              </label>
              <input
                type='email'
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                placeholder='Email'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && (
                <div className='invalid-feedback'>{errors.email}</div>
              )}
            </div>

            <div>
              <button
                type='button'
                className='btn mt-3 '
                onClick={handlePreviousClick}>
                Previous
              </button>
              <button type='submit' className='btn mt-3  ' disabled={isLoading}>
                {isLoading ? (
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                ) : (
                  "  Submit"
                )}
              </button>
            </div>
          </>
        )}
      </form>
      {successMessage && (
        <div className='alert alert-success  mt-4' role='alert'>
          {successMessage}
        </div>
      )}
    </React.Fragment>
  );
};

export default GetAQuote;
